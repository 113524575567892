import * as React from 'react';

import logo from './assets/images/logo.png';
import { ReactComponent as Twitter } from './assets/svg/twitter.svg';
import { ReactComponent as Telegram } from './assets/svg/telegram.svg';
import { ReactComponent as Reddit } from './assets/svg/reddit.svg';
import { ReactComponent as Medium } from './assets/svg/medium.svg';
import { ReactComponent as Discord } from './assets/svg/discord.svg';
import { ReactComponent as Bg } from './assets/svg/bg.svg';

const IndexPage = () => (
  <div className='home-static'>
    <div className='home-static__leftside'>
      <img src={logo} alt='logo' />
      <div className='home-static__rightside-socials'>
        <a
          href='https://twitter.com/airdao_io'
          target='_blank'
          className=''
          rel='noreferrer'
        >
          <Twitter />
        </a>
        <a
          href='https://t.me/airDAO_official'
          target='_blank'
          className=''
          rel='noreferrer'
        >
          <Telegram />
        </a>
        <a
          href='https://www.reddit.com/r/AirDAO/'
          target='_blank'
          className=''
          rel='noreferrer'
        >
          <Reddit />
        </a>
        <a
          href='https://blog.airdao.io/'
          target='_blank'
          className=''
          rel='noreferrer'
        >
          <Medium />
        </a>
        <a
          href='https://discord.gg/nsNvbCzP'
          target='_blank'
          className=''
          rel='noreferrer'
        >
          <Discord />
        </a>
      </div>
    </div>
    <div className='home-static__rightside'>
      <div className='home-static__rightside-info'>
        <div className='home-static__rightside-content'>
          <h1 className='home-static__rightside-content-title'>
            Ambrosus Ecosystem <br/> is now AirDAO
          </h1>
          <p className='home-static__rightside-text'>
            AirDAO is the new DAO governing the Ambrosus Network.
            Our mission is to make Web3 accessible to all, with easy-to-use
            interfaces that focus on putting the user first. The products
            and AMB token from the Ambrosus Ecosystem that you are familiar
            with are now part of AirDAO.
          </p>
        </div>
        <div className='home-static__rightside-botom'>
          <a
            href='https://airdao.io/'
            target='_blank'
            className='home-static__rightside-btn'
            rel='noreferrer'
          >
            Go to AirDAO
          </a>
          <a
            href='https://blog.airdao.io/the-launch-of-airdao-b4785bd43021'
            target='_blank'
            className='home-static__rightside-url'
            rel='noreferrer'
          >
            About the new brand →
          </a>
        </div>
        <Bg className="home-bg" />
      </div>
      <div className='home-static__rightside-info'>
        <div className='home-static__rightside-content'>
          <h2 className='home-static__rightside-content-title'>
            Built on AMB-NET
          </h2>
          <p className='home-static__rightside-text'>
            The Ambrosus Network is a Layer-1, proof-of-authority blockchain. <br/><br/>
            With its three main components — blockchain layer, public data storage,
            and gateway layer — Ambrosus is optimized for the future of Web3 with AirDAO.
            Join us today.
          </p>
        </div>
        <div className='home-static__rightside-botom'>
          <a
            href='https://github.com/ambrosus'
            target='_blank'
            className='home-static__rightside-url'
            style={{marginTop: 40}}
            rel='noreferrer'
          >
            GitHub →
          </a>
        </div>
      </div>
      <div className='home-static__rightside-socials_mobile'>
        <a
          href='https://twitter.com/airdao_io'
          target='_blank'
          className=''
          rel='noreferrer'
        >
          <Twitter />
        </a>
        <a
          href='https://t.me/airDAO_official'
          target='_blank'
          className=''
          rel='noreferrer'
        >
          <Telegram />
        </a>
        <a
          href='https://www.reddit.com/r/AirDAO/'
          target='_blank'
          className=''
          rel='noreferrer'
        >
          <Reddit />
        </a>
        <a
          href='https://blog.airdao.io/'
          target='_blank'
          className=''
          rel='noreferrer'
        >
          <Medium />
        </a>
        <a
          href='https://discord.gg/nsNvbCzP'
          target='_blank'
          className=''
          rel='noreferrer'
        >
          <Discord />
        </a>
      </div>
    </div>
  </div>
);

export default IndexPage;
